import { PositionEnum } from '../constants'

export function localizePosition(position: PositionEnum) {
  const { $i18n } = useNuxtApp()

  switch (position) {
    case PositionEnum.DOCTOR: {
      return $i18n.t('staffs.position.doctor')
    }
    case PositionEnum.BE_SCI: {
      return $i18n.t('staffs.position.beSci')
    }
    case PositionEnum.NUTRITIONIST: {
      return $i18n.t('staffs.position.nutritionist')
    }
    case PositionEnum.PHYSICAL_EDUCATOR: {
      return $i18n.t('staffs.position.physicalEducator')
    }
    case PositionEnum.OPERATIONS: {
      return $i18n.t('staffs.position.operations')
    }
    case PositionEnum.GENERAL: {
      return $i18n.t('staffs.position.general')
    }
    default: {
      throw new Error(`[localizePosition] Invalid enum value: ${position}`)
    }
  }
}
